.signup-page {
}

.signup-form {
  width: 50%;
}

.error-msg {
  color: $error-color;
  margin-bottom: 10px;
}
