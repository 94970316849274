.user-request-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  gap: 10px;
}

.user-request-card {
  background: $secondary-background;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.user-requester {
  font-size: 1rem;
  color: $highlight;
  margin: 0 0 15px;
  text-transform: capitalize;
}

.user-request-date {
  font-size: 0.8rem;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 5px;
    font-size: 0.95rem;
  }
}

.user-request-items {
  font-size: 0.8rem;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 5px;
    font-size: 0.95rem;
  }
}

.user-request-item {
  margin-bottom: 10px;
}

.user-request-item:last-child {
  margin-bottom: 20px;
}

.user-request-btn-container {
  margin-top: auto;
}

.user-fulfilled {
  font-size: 0.9rem;
  color: $error-color;
}

.user-request-btn {
  font-size: 0.9rem;
}
