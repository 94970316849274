* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background: $primary-background;
    font-family: $sans-serif;
    overscroll-behavior-y: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: $font-color;
}

h1 {
    margin: 0;
}

h2 {
    margin: 0;
}

h3 {
    margin: 0;
}

p {
    margin: 0;
}