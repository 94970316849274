.stats-datepicker {
    margin: 0;
    h2 {
        margin-bottom: 10px;
    }
    p {
        margin: 10px 0px;
    }

    .stats-to {
        margin-left: 10px;
        font-weight: 700;
        color: $highlight;
    }
}

.date-error {
    color: $error-color;
}

.stats {
    h2 {
        margin: 10px 0px;
    }
}