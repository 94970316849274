.training-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
}

.training-card {
  background: $secondary-background;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.training-title {
  font-size: 1rem;
  color: $highlight;
  margin: 0 0 15px;
  text-transform: capitalize;
}

.training-date {
  font-size: 0.8rem;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 5px;
    font-size: 0.95rem;
  }
}

.training-description {
  font-size: 0.8rem;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 5px;
    font-size: 0.95rem;
  }
}

.training-items {
  font-size: 0.8rem;
  h3 {
    margin-bottom: 5px;
    font-size: 0.95rem;
  }
}

.training-attendee {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.training-btn-container {
  margin-top: auto;
}

.training-btn {
  font-size: 0.9rem;
}

.training-delete {
  display: block;
  position: absolute;
  top: 20px;
  right: 15px;
}

@media (max-width: 800px) {
  .training-container {
    grid-template-columns: 100%;
  }
}
