%forms {
    display: block;
    border-radius: 5px;
    border: .3px solid black;
    padding: 1rem;
    outline: none;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
    font-family: $sans-serif;
    font-size: 1rem;
}

%forms-focus {
    outline: 0;
    border: .3px solid white;
    box-shadow: $box-shadow;
}

#{$forms} {
    @extend %forms;

    &:focus,
    &:active {
        @extend %forms-focus;
    }
}