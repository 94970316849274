.side-bar {
  width: 15%;
  max-width: 200px;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: $secondary-background;
}

.header-top {
  align-items: center;
}

.logo-container {
  width: 100%;
  text-align: center;

  h1 {
    color: $highlight;
    font-size: 0.9rem;
    text-align: center;
    margin: 30px 0px;
  }
}

.side-bar-nav {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  li:last-child {
    margin-bottom: 30px;
  }
}

.nav-toggle {
  display: none;
}

@media (max-width: 800px) {
  .side-bar {
    position: sticky;
    top: 0;
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: none;
    margin-left: 0px;
    padding: 0px;
    height: auto;

    h1 {
      margin: 20px 20px;
    }
  }

  .side-bar-nav {
    position: fixed;
    background: #444444;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    transform: translateX(100%);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
  }

  .nav-links {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .nav-toggle {
    display: block;
    padding: 10px 0.5em;
    background: none;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 50%;
    z-index: 1000000;
    transform: translateY(-50%);
  }

  .nav-toggle:hover,
  .nav-toggle:active,
  .nav-toggle:focus {
    background: none;
  }

  .nav-open .side-bar-nav {
    transform: translateX(0);
  }

  .nav-open {
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .nav-toggle {
    position: absolute;
  }

  .nav-open .hamburger {
    transform: rotate(0.625turn);
  }

  .nav-open .hamburger::after {
    opacity: 0;
  }

  .nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-6px);
  }

  .hamburger {
    display: block;
    position: relative;
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    background: white;
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
  }

  .hamburger::before,
  .hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
  }

  .hamburger::before {
    top: 6px;
  }

  .hamburger::after {
    bottom: 6px;
  }
}
