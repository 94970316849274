%buttons {
  display: block;
  border: none;
  border-radius: 5px;
  background: $highlight;
  font-family: $sans-serif;
  font-size: 1rem;
  padding: 10px 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: $primary-background;
  transition: 300ms ease-in-out;
  width: auto;
  font-weight: 500;
}

%buttons-hover {
  background: darken($highlight, 10%);
}

%buttons-focus {
  background: darken($highlight, 10%);
}

%buttons-disabled {
  background: $primary-background;
  color: $font-color;
  cursor: not-allowed;
}

#{$buttons} {
  @extend %buttons;

  &.sidebar-link {
    background: $secondary-background;
    border-radius: 0px;
    font-size: 0.9rem;
    color: $font-color;
    font-weight: 400;
    padding: 12px 0px;
    &:hover,
    &:focus {
      border-left: 2px solid $highlight;
      background: darken($secondary-background, 5%);
    }
    svg {
      width: 90%;
    }
  }

  &.sidebar-btn {
    width: 100%;
  }

  @media (max-width: 800px) {
    &.sidebar-link {
      width: 100%;
      &:hover,
      &:focus {
        border-left: 0px;
      }
    }
  }

  &.sidebar-link-active {
    border-left: 2px solid $highlight;
    background: darken($secondary-background, 10%);
    color: $highlight;
    svg {
      color: $highlight;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    &.sidebar-link-active {
      border-left: 0px solid $highlight;
    }

    &.sidebar-link {
      &:hover,
      &:focus {
        border-left: 0px;
      }
    }
  }

  &.delete-btn {
    background: none;
    color: $font-color;
    padding: 10px;
    margin: 0 auto;
  }

  &.edit-btn {
    background: none;
    color: $font-color;
    padding: 10px;
    margin: 0 auto;
  }

  &.add-btn {
    padding: 10px 30px;
    width: auto;
    &:hover,
    &:focus {
      background: darken($highlight, 10%);
    }
  }

  &.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    font-family: inherit;
  }

  &.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: $primary-background;
  }

  &.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: $primary-background;
  }

  &:hover,
  &:active {
    @extend %buttons-hover;
  }

  &:focus {
    @extend %buttons-focus;
  }

  &:disabled {
    @extend %buttons-disabled;
  }
}
